@import './base';
@import './buttons';
@import './typography';
@import './reset';
@import './material';

.navigation__container {
  display: none;
  min-width: 150px;
  flex-direction: column;
  position: absolute;
  padding: 0;
  background: var(--light);
  box-shadow: var(--box-shadow-bold);
  border-radius: var(--border-radius-x-sm);
  &::after {
    bottom: 100%;
    right: 1rem;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
}
