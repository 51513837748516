.e-treeview {
  border-bottom: 1px solid var(--color-border);
}

.e-treeview .e-list-item {
  padding: 0;
}

.e-treeview .e-list-item.e-editing .e-icon-expandable,
.e-treeview .e-list-item.e-editing .e-icon-collapsible {
  display: none;
}

.e-treeview .e-text-content {
  display: flex;
  width: 100%;
  align-items: center;
  .e-icon-expandable,
  .e-icon-collapsible {
    margin-left: 0;
    position: absolute;
    right: 52px;
  }
}

.e-drag-item.e-treeview .e-icon-expandable,
.e-drag-item.e-treeview .e-icon-collapsible {
  position: relative;
  right: unset;
}

.e-treeview .e-list-item .e-ul {
  display: flex;
  margin: 0;
  width: 100%;
  overflow: unset !important;
}

.e-treeview .e-text-content,
.e-treeview .e-fullrow {
  height: 48px;
}

.e-treeview .e-text-content {
  padding: 0;
}

.e-treeview .e-list-text {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-mont);
}

.e-treeview .e-list-item.e-active.e-node-focus .e-fullrow,
.e-treeview .e-list-item.e-active.e-hover .e-fullrow,
.e-treeview .e-list-item.e-active .e-fullrow {
  background-color: #e4f8e5;
  border-left: 5px solid var(--primary);
}

.e-treeview .e-list-item .e-list-icon {
  margin: 0;
}

.e-treeview .e-list-icon {
  &.FOLDER {
    background-image: url('/assets/icons/folder.svg');
  }
  &.WIDGET {
    background-image: url('/assets/icons/dashboard.svg');
  }
}

.e-treeview .e-list-item.e-active.e-node-focus > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: var(--dark);
}

.e-treeview .e-list-item.e-level-1 .e-fullrow {
  border: 0;
  border-top: 1px solid var(--color-border);
}

.e-input-group {
  width: 100% !important;
}
.e-treeview .e-list-item div.e-icons.interaction {
  cursor: pointer;
}
