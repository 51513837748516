/* Variables */
@import "./lib/styles/variables/variables";

@import "./lib/styles/base/main";

/* SyncFusion */
@import "./lib/styles/vendors/sync-fusion/sync-fusion";

// Material UI
@import "./lib/styles/vendors/materials/material";

@tailwind base;
@tailwind components;
@tailwind utilities;
