:root {
  --primary: #1de62a;
  --secondary: #1d72e6;
  --tertiary: #0d6613;
  --dark: #000000;
  --light: #ffffff;
  --metadata: #8f8f90;
  --danger: #e85e6c;
  --warning: #dbf534;
  --color-border: #e1e1e1;
  --color-background: #fafafa;
  --color-background-secondary: #eaf1fb;
  --primary-over: #e4f8e5;
}
