:root {
  --height: 54px;
}

.mat-accordion.default {
  border-radius: var(--border-radius-x-sm);
  .mat-expansion-panel {
    &:last-child {
      margin-bottom: 0;
    }
    &:not([class*='mat-elevation-z']) {
      box-shadow: unset;
    }
  }
  .mat-expansion-panel-header {
    height: var(--height);
    padding: 0 var(--margin-sm);
    border-radius: 0;
    .mat-panel-title {
      font-size: var(--font-size-lg);
    }
    .mat-content {
      align-items: center;
    }
    .mat-expansion-panel-header-title {
      font-weight: var(--font-weight-semibold);
    }
    .mat-expansion-panel-header-description {
      justify-content: right;
    }
  }
  .mat-panel-description {
    justify-content: flex-end;
  }
  .mat-expansion-panel-content {
    border-top: 1px solid var(--color-border);
  }
}

.mat-accordion.basic {
  .mat-expansion-panel {
    box-shadow: none;
    background: transparent;
    width: 100%;
    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-panel-header {
      height: var(--table-row-height);
      &:hover {
        background-color: transparent !important;
      }
      .mat-content {
        align-items: center;
      }
      .mat-expansion-panel-header-title {
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
        flex-grow: unset;
        flex-basis: auto;
        border-bottom: 2px solid var(--primary);
        line-height: 1.5;
        margin: 0;
        fa-icon {
          padding-right: var(--margin-xx-sm);
        }
      }
    }
  }
}

.mat-accordion.secondary {
  .mat-expansion-panel {
    border: 1px solid var(--primary);
    box-shadow: none;
    .mat-expansion-panel-header {
      padding: 0 var(--margin-x-sm);
      height: var(--table-row-height);
      .mat-expansion-panel-header-title {
        font-weight: var(--font-weight-semibold);
      }
    }
    .mat-expansion-panel-content {
      border-top: 1px solid var(--primary);
      .mat-expansion-panel-body {
        padding: var(--margin-x-sm);
      }
    }
  }
}

.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: transparent;
}
