.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
}

.mat-tab-label {
  color: var(--dark) !important;
  opacity: 0.6 !important;
  &.mat-tab-label-active {
    opacity: 1 !important;
  }
}

.mat-tab-label-content {
  text-transform: initial;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  .tab__wrapper_left fa-icon {
    margin-right: var(--margin-x-sm);
  }
}

.mat-slide-toggle-content {
  font-weight: var(--font-weight-normal);
}

.mat-tab-group.tabs__toggle {
  .mat-tab-header {
    border: 0;
    .mat-tab-label {
      height: var(--control-height);
      .mat-tab-label-content {
        text-transform: capitalize;
      }
    }
  }
  .mat-ink-bar {
    height: 2px;
  }
}

.mat-mdc-tab {
  letter-spacing: normal !important;
}


.mdc-tab__text-label {
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}