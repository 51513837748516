@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Black.eot");
  src: url("../../fonts/Mont-Black.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Black.svg#Mont-Black") format("svg"),
    url("../../fonts/Mont-Black.ttf") format("truetype"),
    url("../../fonts/Mont-Black.woff") format("woff"),
    url("../../fonts/Mont-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Heavy.eot");
  src: url("../../fonts/Mont-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Heavy.svg#Mont-Heavy") format("svg"),
    url("../../fonts/Mont-Heavy.ttf") format("truetype"),
    url("../../fonts/Mont-Heavy.woff") format("woff"),
    url("../../fonts/Mont-Heavy.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Bold.eot");
  src: url("../../fonts/Mont-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Bold.svg#Mont-Bold") format("svg"),
    url("../../fonts/Mont-Bold.ttf") format("truetype"),
    url("../../fonts/Mont-Bold.woff") format("woff"),
    url("../../fonts/Mont-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-SemiBold.eot");
  src: url("../../fonts/Mont-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-SemiBold.svg#Mont-SemiBold") format("svg"),
    url("../../fonts/Mont-SemiBold.ttf") format("truetype"),
    url("../../fonts/Mont-SemiBold.woff") format("woff"),
    url("../../fonts/Mont-SemiBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Regular.eot");
  src: url("../../fonts/Mont-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Regular.svg#Mont-Regular") format("svg"),
    url("../../fonts/Mont-Regular.ttf") format("truetype"),
    url("../../fonts/Mont-Regular.woff") format("woff"),
    url("../../fonts/Mont-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Light.eot");
  src: url("../../fonts/Mont-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Light.svg#Mont-Light") format("svg"),
    url("../../fonts/Mont-Light.ttf") format("truetype"),
    url("../../fonts/Mont-Light.woff") format("woff"),
    url("../../fonts/Mont-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-ExtraLight.eot");
  src: url("../../fonts/Mont-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-ExtraLight.svg#Mont-ExtraLight") format("svg"),
    url("../../fonts/Mont-ExtraLight.ttf") format("truetype"),
    url("../../fonts/Mont-ExtraLight.woff") format("woff"),
    url("../../fonts/Mont-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Thin.eot");
  src: url("../../fonts/Mont-Thin.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Mont-Thin.svg#Mont-Thin") format("svg"),
    url("../../fonts/Mont-Thin.ttf") format("truetype"),
    url("../../fonts/Mont-Thin.woff") format("woff"),
    url("../../fonts/Mont-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
