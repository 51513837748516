.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent !important;
  .stepper_footer {
    display: flex;
    width: 100%;
    padding-top: var(--margin-sm);
    padding-bottom: var(--margin-x-sm);
    justify-content: space-between;
  }
}

.mat-step-header {
  margin-bottom: var(--margin-x-sm);
  .mat-step-icon {
    background-color: var(--dark) !important;
    color: var(--light) !important;
    .mat-step-icon-content {
      fa-icon {
        color: var(--light) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mat-step-icon-selected {
    background-color: var(--primary) !important;
    .mat-step-icon-content {
      fa-icon {
        color: var(--dark) !important;
      }
    }
  }
}

.mat-vertical-content-container {
  margin-left: 24px !important;
  .mat-vertical-content {
    padding-bottom: 0;
    padding-right: 0;
    padding-left: var(--margin-md);
  }
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: var(--margin-sm) !important;
}

.mat-horizontal-stepper-header {
  height: 60px !important;
}

.mat-step-header .mat-step-icon-state-done {
  color: var(--primary) !important;
}

.mat-horizontal-stepper-wrapper {
  width: 100%;
  .mat-horizontal-content-container {
    overflow: hidden !important;
    flex: 1;
    display: flex;
    flex-direction: column;

    .mat-horizontal-stepper-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      &.mat-horizontal-stepper-content-inactive {
        height: 0 !important;
      }
      itq-form {
        flex: 1;
        overflow: hidden;
        width: 100%;
        form {
          flex-direction: column;
          itq-timeline-type-all {
            flex: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: var(--breakpoints-lg-laptop)) {
  .mat-vertical-content-container {
    margin-left: 24px !important;
  }
}

.mat-horizontal-content-container {
  padding: 0 24px !important;
}
