@include export-module('rating-layout') {
  .e-rating-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-width: max-content;

    .e-rating {
      display: none;
    }

    .e-rating-item-list {
      display: inline-flex;
      padding: 0;
      margin: 4px;

      &:focus-visible:not(:hover) .e-rating-focus {
        outline: 1px solid;
      }

      &:focus-visible {
        outline: none;
      }
    }

    &.e-rating-readonly {
      cursor: default;
      pointer-events: none;
    }

    .e-rating-item-container {
      cursor: pointer;
      font-size: $rating-li-font-size;
      padding: $rating-li-padding;
      display: block;
      min-width: calc(1em + (2 * $rating-li-padding)); //calc(font-size + (2 * padding));
      min-height: calc(1em + (2 * $rating-li-padding)); //calc(font-size + (2 * padding));
      //For Blazor Tooltip
      position: relative;

      .e-rating-item {
        display: block;
      }
    }

    &.e-rating-animation:not(.e-disabled) {
      .e-rating-item-container:hover .e-rating-item {
        transition: transform 150ms cubic-bezier(.4, 0, .2, 1) 0ms;
        transform: scale(1.4);
        pointer-events: none;
      }
    }

    &.e-disabled {
      cursor: default;
      pointer-events: none;
    }

    &.e-rating-hidden {
      display: none;
    }

    .e-reset {
      cursor: pointer;
      margin: auto 2px 6px;
      font-size: $rating-reset-font-size;
      padding: 2px;

      &.e-disabled {
        cursor: default;
      }

      &:focus-visible{
        outline: 1px solid;
        border-radius: $rating-focus-border-radius;
      }
    }

    .e-rating-label {
      font-size: $rating-label-font-size;
      line-height: $rating-label-line-height;

      &.e-label-right,
      &.e-label-left {
        min-width: 50px;
      }

      &.e-label-bottom,
      &.e-label-top {
        flex-basis: 100%;
        width: 0;
        text-align: center;
      }

      &.e-label-right {
        text-align: left;
        margin: auto $rating-label-right-margin;
      }

      &.e-label-left {
        text-align: right;
        margin: auto $rating-label-left-margin;
      }

      &.e-label-top {
        margin: $rating-label-top-margin 0;
      }

      &.e-label-bottom {
        margin: $rating-label-bottom-margin 0;
      }
    }

    &.e-rtl {
      .e-rating-label {
        &.e-label-right {
          text-align: right;
        }

        &.e-label-left {
          text-align: left;
        }
      }
    }
    //Blazor Rating Tooltip
    .e-tooltip-wrap.e-rating-tooltip {
      display: none;
      justify-content: center;
      left: 50%;
      top: -$rating-tooltip-tip-bottom-height;
      transform: translate(-50%, -100%);

      &.e-show-tooltip {
        display: inline-flex;
      }

      .e-tip-content {
        width: max-content;
      }

      .e-arrow-tip.e-tip-bottom {
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
      }

      .e-arrow-tip-inner.e-tip-bottom {
        top: -6px;
      }
    }
  }

  .e-rating-tooltip .e-tip-content {
    text-align: center;
  }

  .e-small.e-rating-container,
  .e-small .e-rating-container {
    .e-rating-item-container {
      font-size: $rating-small-li-font-size;
    }

    .e-rating-label {
      font-size: $rating-small-label-font-size;
      line-height: $rating-small-label-line-height;
    }

    .e-reset {
      font-size: $rating-small-reset-font-size;
    }
  }

  .e-bigger.e-rating-container,
  .e-bigger .e-rating-container {
    .e-rating-item-container {
      font-size: $rating-bigger-li-font-size;
    }

    .e-rating-label {
      font-size: $rating-bigger-label-font-size;
      line-height: $rating-bigger-label-line-height;
    }

    .e-reset {
      font-size: $rating-bigger-reset-font-size;
    }
  }

  .e-bigger.e-small.e-rating-container,
  .e-bigger.e-small .e-rating-container,
  .e-bigger .e-small.e-rating-container,
  .e-small .e-bigger.e-rating-container {
    .e-rating-item-container {
      font-size: $rating-bigger-small-li-font-size;
    }

    .e-rating-label {
      font-size: $rating-bigger-small-label-font-size;
      line-height: $rating-bigger-small-label-line-height;
    }

    .e-reset {
      font-size: $rating-bigger-small-reset-font-size;
    }
  }
}
