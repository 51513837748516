.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--dark) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--dark) !important;
}

.mat-radio-group {
  .mat-radio-button {
    margin-right: var(--margin-sm);
  }
}
.mat-mdc-radio-button {
  .mdc-label {
    font-family: var(--font-family-mont);
  }
}
