:root {
  --font-weight-normal: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --font-weight-light: 300;

  --font-size-xxx-lg: 27px;
  --font-size-xx-lg: 24px;
  --font-size-x-lg: 20px;
  --font-size-lg: 17px;
  --font-size-md: 15px;
  --font-size-sm: 14px;
  --font-size-x-sm: 13px;

  --font-family-roboto: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  --font-family-rubik: 'Rubik', 'Helvetica Neue', Arial, sans-serif;
  --font-family-mont: 'Mont', 'Helvetica Neue', Arial, sans-serif;
}
