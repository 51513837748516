.mat-step-label.mat-step-label-selected {
  font-weight: var(--font-weight-semibold) !important;
  font-size: var(--font-size-sm) !important;
}

.mat-datepicker-content {
  .mat-datepicker-actions button {
    box-shadow: none !important;
  }
}

.mat-tooltip {
  display: flex;
  position: relative;
  z-index: 1000000;
  margin-left: 20px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%) !important;
  background-color: var(--primary) !important;
  font-size: 13px !important;
  color: var(--dark) !important;
  padding: var(--margin-sm) !important;
  font-weight: var(--font-weight-semibold);
}

.mat-mdc-form-field-error {
  color: var(--danger) !important;
  font-weight: var(--font-weight-semibold) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--dark) !important;
}
